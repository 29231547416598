<template>
  <FormElement
    :rules="props.rules"
    :input-value="props.modelValue"
    :error="sizeExceededMessage"
  >
    <input
      :id="parsedId"
      ref="fileInput"
      type="file"
      hidden
      :accept="props.acceptedTypes"
      @change="onFileChange"
    />

    <div class="flex w-full h-full relative">
      <button
        v-if="!props.modelValue"
        type="button"
        class="w-full h-full px-4 border border-dashed rounded-lg justify-center flex flex-col items-center"
        :class="{ 'cursor-not-allowed': props.disabled }"
        :disabled="props.disabled"
        @click="browseFiles()"
      >
        <CircularSpinner v-if="isUploading" />
        <template v-else>
          <Icon :icon="AddCircleSvg" :size="IconSize.XL" />
          <span class="text-sm text-on-surface font-bold">
            {{ props.title }}
          </span>
          <span class="text-xs text-on-surface-variant font-bold">
            {{ props.typeDescription }}
          </span>
        </template>
      </button>
      <div v-else class="relative w-full h-full">
        <Backdrop v-if="isUploading">
          <CircularSpinner />
        </Backdrop>
        <img
          v-if="isFileImage(props.modelValue)"
          :src="props.modelValue"
          class="w-full h-full rounded-lg flex items-center justify-center"
          :class="props.imageObjectContain ? 'object-contain' : 'object-cover'"
          alt="logo"
        />
        <video
          v-else-if="isFileVideo(props.modelValue)"
          controls
          class="h-full w-full rounded-lg bg-disabled"
        >
          <source :src="`${props.modelValue}#t=0.001`" />
        </video>
        <div
          v-else
          class="w-full h-full border rounded-lg flex items-center justify-center"
        >
          <FilePlaceholder class="h-full p-4" />
        </div>
        <div v-if="!props.disabled" class="absolute top-2 right-2 flex gap-2">
          <slot name="icons" />
          <Btn
            v-if="!hideEdit"
            :variant="BtnVariant.FILLED"
            :size="BtnSize.SM"
            :shape="BtnShape.ROUNDED"
            :color="BtnColor.SURFACE"
            icon
            @click="browseFiles(true)"
          >
            <icon :icon="IconEditSvg" />
          </Btn>
          <Btn
            v-if="!hideDelete"
            :variant="BtnVariant.FILLED"
            :shape="BtnShape.ROUNDED"
            :size="BtnSize.SM"
            :color="BtnColor.SURFACE"
            icon
            @click="deleteFile()"
          >
            <Icon :icon="BasketSvg" />
          </Btn>
        </div>
      </div>
    </div>
  </FormElement>
</template>

<script setup lang="ts">
import { computed, ref } from '#imports';
import { getUid } from '~/utils/common';
import AddCircleSvg from '~/assets/icons/add-circle.svg?component';
import IconEditSvg from '~/assets/icons/edit.svg?component';
import BasketSvg from '~/assets/icons/basket.svg?component';
import FilePlaceholder from '~/assets/images/placeholders/file-placeholder.svg?component';
import { Btn, BtnColor, BtnShape, BtnSize, BtnVariant } from '~/components/btn';
import { Icon, IconSize } from '~/components/icon';
import { CircularSpinner } from '~/components/loaders';
import { useFirebase } from '~/composables/use-firebase';
import {
  getDownloadURL,
  ref as getStorageRef,
  uploadBytes,
} from 'firebase/storage';
import { useOrganizationStore } from '~/store/organization';
import {
  getFileTypeByName,
  humanFileSize,
} from '~/pages/_components/content-details/content.utils';
import { FormElement } from '@/components/inputs/_components/form-element';
import { FileType } from '~/pages/_components/content-details/content.constants';
import { Backdrop } from '~/components/backdrop';
import { createStorageFilePath, isFileImage, isFileVideo } from '~/utils/file';
import { create as createContentDisposition } from 'content-disposition-header';
import { notify } from '@kyvg/vue3-notification';

type Props = {
  modelValue: string | null;
  id?: string;
  acceptedTypes?: string;
  title?: string;
  typeDescription?: string;
  disabled?: boolean;
  rules?: [];
  imageSizeLimit?: number;
  videoSizeLimit?: number;
  imageObjectContain?: boolean;
  hideEdit?: boolean;
  hideDelete?: boolean;
  validate?: (file: File) => { isValid: boolean; errorMessage: string };
  notifyErrors?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  title: 'Upload File',
  typeDescription: '',
});

const emit = defineEmits<{
  'update:modelValue': [value: string | null];
  'upload-started': [replace: boolean];
  'upload-complete': [url: string, replace: boolean];
  delete: [];
}>();

const { firebaseStorage } = useFirebase();
const config = useRuntimeConfig();
const firebaseFileSizeLimit = Number(config.public.firebaseFileSizeLimit);
const fileInput = ref<HTMLElement | null>(null);
const sizeExceededMessage = ref<string>('');
const isUploading = ref<boolean>(false);
const parsedId = computed<string>(() => {
  return props.id ?? `input-${getUid()}`;
});
const replaceExisting = ref(false);

const browseFiles = (browseToReplaceExisting?: boolean) => {
  replaceExisting.value = Boolean(browseToReplaceExisting);
  fileInput.value?.click();
};

const onFileChange = async (e: Event) => {
  const target = e.target as HTMLInputElement;
  const file = target.files ? target.files[0] : null;
  if (!file) {
    return;
  }

  const fileType = getFileTypeByName(file.name);

  if (
    props.videoSizeLimit &&
    fileType === FileType.VIDEO &&
    (props.videoSizeLimit < file.size || firebaseFileSizeLimit < file.size)
  ) {
    const error = `Video cannot exceed ${humanFileSize(
      Math.min(props.videoSizeLimit, firebaseFileSizeLimit),
    )}`;

    if (props.notifyErrors) {
      notify({
        group: 'error',
        title: error,
      });

      return;
    }

    sizeExceededMessage.value = error;

    return;
  }

  if (
    props.imageSizeLimit &&
    fileType === FileType.IMAGE &&
    (props.imageSizeLimit < file.size || firebaseFileSizeLimit < file.size)
  ) {
    const error = `Image cannot exceed ${humanFileSize(
      Math.min(props.imageSizeLimit, firebaseFileSizeLimit),
    )}`;

    if (props.notifyErrors) {
      notify({
        group: 'error',
        title: error,
      });

      return;
    }

    sizeExceededMessage.value = error;

    return;
  }
  sizeExceededMessage.value = '';

  if (props.validate) {
    const { isValid, errorMessage } = props.validate(file);

    if (!isValid) {
      notify({
        group: 'error',
        title: errorMessage,
      });
      return;
    }
  }

  try {
    isUploading.value = true;
    emit('upload-started', replaceExisting.value);

    const storageRef = getStorageRef(
      firebaseStorage,
      createStorageFilePath(file.name),
    );

    const metadata = {
      contentType: file.type,
      contentDisposition: createContentDisposition(file.name),
    };

    const snapshot = await uploadBytes(storageRef, file, metadata);

    const fileUrl = await getDownloadURL(snapshot.ref);
    emit('update:modelValue', fileUrl);
    emit('upload-complete', fileUrl, replaceExisting.value);
    target.value = '';
  } finally {
    isUploading.value = false;
    replaceExisting.value = false;
  }
};

const deleteFile = () => {
  emit('delete');
  fileInput.value = null;
  emit('update:modelValue', null);
};

defineExpose({
  browseFiles,
});
</script>
